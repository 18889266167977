import { Suspense, useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'

// Redux store
import { useDispatch } from 'react-redux'
import { changeLoading, changeSettings } from 'store/store'

// Components
import { Loading } from 'components'

// Helpers
import { checkTokenValidity, handleOnline, hexToHSL, hslColorVariants, switchTheme } from 'helpers'

// Custom hooks
import { useTypedSelector } from 'hooks'

// Routes list
import generateRoutes from './routesLinks'

// Types
import { IRootState } from 'types'
import myAxios from 'service/axios'

export default function Router(): JSX.Element {
  const dispatch = useDispatch()
  const {
    isLoading,
    settings,
    user: { isAuth, rolesId },
  } = useTypedSelector((s: IRootState) => s.store)

  const [isOnline, setIsOnline] = useState(true)
  const routes = useRoutes(generateRoutes(isAuth, rolesId || []))

  const [loading, setLoading] = useState<boolean>(true)

  const changeLoader = (param: boolean) => {
    setLoading(param)
  }

  async function getVisualColors() {
    try {
      const response = await myAxios('/about/visual-colors')
      dispatch(changeSettings(response?.data))
      
    } catch {
      dispatch(changeSettings(settings))
    }
  }

  async function checking() {
    if (!localStorage.getItem('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$T$O$K$E$N$')) {
      dispatch(changeLoading(false))
      setLoading(false)
      return
    }
    await checkTokenValidity(dispatch, changeLoader)
  }

  const changeOnline = (param: boolean) => {
    setIsOnline(param)
  }

  // Device internet connection watcher
  useEffect(() => {
    handleOnline(true, isOnline, changeOnline)
    window.addEventListener('online', () => handleOnline(false, isOnline, changeOnline))
    window.addEventListener('offline', () => handleOnline(false, isOnline, changeOnline))

    return () => {
      window.removeEventListener('online', () => handleOnline(false, isOnline, changeOnline))
      window.removeEventListener('offline', () => handleOnline(false, isOnline, changeOnline))
    }
  }, [])

  // Checking user is admin on device internet connected
  useEffect(() => {
    getVisualColors()
    if (!isOnline) return
    checking()
  }, [isOnline])

  useEffect(() => {
    switchTheme('get')
  }, [])

  return (
    <Suspense fallback={<Loading />}>
      {settings?.visualColors && (
        <style>
          {`:root {
          --visualColorMain: ${settings?.visualColors?.admin ? hexToHSL(settings?.visualColors?.admin) : ''};
          --visualColorSecond: ${settings?.visualColors?.admin ? hslColorVariants(1, hexToHSL(settings?.visualColors?.admin)) : ''};
          --visualColorThird: ${settings?.visualColors?.admin ? hslColorVariants(2, hexToHSL(settings?.visualColors?.admin)) : ''};
          --visualColorFourth: ${settings?.visualColors?.admin ? hslColorVariants(3, hexToHSL(settings?.visualColors?.admin)) : ''};
        }`}
        </style>
      )}
      {!loading && routes}
      {isLoading && <Loading bg={!isAuth} />}
    </Suspense>
  )
}