import { redirect } from 'react-router-dom'
import { createSlice } from '@reduxjs/toolkit'

// Types
import { IAdmin, IAdminRoles, IBrand, ICategoryData, IClients, IColor, ICompanyNewsData, IManufacturer, IPackage, IPagination, IProduct, IProductData, ISalesNews, ISettings, IStatistics, IUser } from 'types'

interface IStoreCache {
  categories: {
    categories: ICategoryData[] | null
    pagination: IPagination
  }
  products: {
    products: IProductData[] | null
    pagination: IPagination
  }
  packaging: {
    packaging: IPackage[] | null
    pagination: IPagination
  }
  manufacturers: {
    manufacturers: IManufacturer[] | null
    pagination: IPagination
  }
  brands: {
    brands: IBrand[] | null
    pagination: IPagination
  }
  colors: {
    colors: IColor[] | null
    pagination: IPagination
  }
  companyNews: {
    companyNews: ICompanyNewsData[] | null
    pagination: IPagination
  }
  users: {
    users: IUser[] | null
    pagination: IPagination
  }
  admins: {
    admins: IAdmin[] | null
    pagination: IPagination
  }
  roles: {
    roles: IAdminRoles[] | null
    pagination: IPagination
  }
  clients: IClients | []
  salesBanners: {
    salesBanners: ISalesNews[] | null
    pagination: IPagination
  },
  statistics: IStatistics | null
}

interface IInitialState {
  isLoading: boolean
  isFullHeight: boolean
  cache: IStoreCache
  user: IAdmin
  settings: ISettings | null
}

const initialState: IInitialState = {
  isLoading: true,
  isFullHeight: false,
  cache: {
    categories: {
      categories: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    products: {
      products: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    packaging: {
      packaging: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    manufacturers: {
      manufacturers: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    brands: {
      brands: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    colors: {
      colors: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    companyNews: {
      companyNews: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    salesBanners: {
      salesBanners: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    users: {
      users: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    admins: {
      admins: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    roles: {
      roles: null,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        totalPages: 0,
      },
    },
    clients: [],
    statistics: null,
  },
  user: {
    _id: '',
    id: '',
    name: '',
    email: '',
    image: '',
    surname: '',
    username: '',
    isAuth: false,
    userId: '',
    userToken: localStorage.getItem('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$T$O$K$E$N$') || '',
  },
  settings: null,
}

const store = createSlice({
  name: 'store',
  initialState,
  reducers: {
    changeLoading: (state, action) => {
      state.isLoading = action?.payload
    },
    changeFullHeight: (state, action) => {
      state.isFullHeight = action?.payload
    },
    changeCategories: (state, action) => {
      state.cache.categories = action?.payload
    },
    changeProducts: (state, action) => {
      state.cache.products = action?.payload
    },
    changeProductHide: (state, action) => {
      state.cache.products = { pagination: state?.cache?.products?.pagination, products: state?.cache?.products?.products?.map((product: IProduct) => (product?.id === action?.payload?.id ? { ...product, isHide: action?.payload?.isHide || false } : product)) || [] }
    },
    changeCategoryHide: (state, action) => {
      state.cache.categories = { pagination: state?.cache?.categories?.pagination, categories: state?.cache?.categories?.categories?.map((category: ICategoryData) => (category?.id === action?.payload?.id ? { ...category, isHide: action?.payload?.isHide || false } : category)) || [] }
    },
    changePackaging: (state, action) => {
      state.cache.packaging = action?.payload
    },
    changeManufacturers: (state, action) => {
      state.cache.manufacturers = action?.payload
    },
    changeBrands: (state, action) => {
      state.cache.brands = action?.payload
    },
    changeColors: (state, action) => {
      state.cache.colors = action?.payload
    },
    changeCompanyNews: (state, action) => {
      state.cache.companyNews = action?.payload
    },
    changeUsers: (state, action) => {
      state.cache.users = action?.payload
    },
    changeAdmins: (state, action) => {
      state.cache.admins = action?.payload
    },
    changeAdminRoles: (state, action) => {
      state.cache.roles = action?.payload
    },
    changeClients: (state, action) => {
      state.cache.clients = action?.payload
    },
    changeStatistics: (state, action) => {
      state.cache.statistics = action?.payload
    },
    changeUserProfile: (state, action) => {
      state.user = {
        ...state.user,
        name: action?.payload?.name,
        surname: action?.payload?.surname,
        username: action?.payload?.username,
      }
    },
    changeUserProfileImage: (state, action) => {
      state.user = {
        ...state.user,
        image: action?.payload?.image,
      }
    },
    changeSettings: (state, action) => {
      state.settings = { ...state.settings, ...action?.payload }
      delete (state.settings as any).clients as any
      state.cache.clients = action?.payload?.clients || []
    },
    changeCompanyClientLogos: (state, action) => {
      state.cache.clients = action?.payload || []
    },
    changeSalesBanners: (state, action) => {
      state.cache.salesBanners = action?.payload || []
    },
    userAuth: (state, action) => {
      switch (action?.payload?.type) {
        case 'LOGIN':
          state.user = {
            isAuth: true,
            name: action?.payload?.data?.user?.name,
            email: action?.payload?.data?.user?.email,
            image: action?.payload?.data?.user?.image || '',
            surname: action?.payload?.data?.user?.surname,
            username: action?.payload?.data?.user?.username,
            roles: action?.payload?.data?.user?.roles,
            rolesId: action?.payload?.data?.user?.rolesId,
            _id: action?.payload?.data?.user?._id,
            id: action?.payload?.data?.user?.id,
            userId: action?.payload?.data?.user?._id,
            userToken: action?.payload?.data?.access_token,
          }
          localStorage.setItem('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$T$O$K$E$N$', action?.payload?.data?.access_token)
          redirect('/dashboard')
          break

        case 'LOGOUT':
          state.user = {
            isAuth: false,
            _id: '',
            id: '',
            name: '',
            email: '',
            image: '',
            surname: '',
            username: '',
            userId: '',
            userToken: '',
          }
          localStorage.removeItem('$W$E$B$A$B$R$O$R$M$A$R$K$E$T$T$O$K$E$N$')
          localStorage.removeItem('$abrormarketadmindashboard$theme$')
          break
      }
    },
  },
})

export const {
  userAuth,
  changeUsers,
  changeColors,
  changeBrands,
  changeAdmins,
  changeClients,
  changeLoading,
  changeSettings,
  changeProducts,
  changePackaging,
  changeAdminRoles,
  changeCategories,
  changeStatistics,
  changeFullHeight,
  changeProductHide,
  changeUserProfile,
  changeCompanyNews,
  changeCategoryHide,
  changeSalesBanners,
  changeManufacturers,
  changeUserProfileImage,
  changeCompanyClientLogos,
} = store?.actions
export default store.reducer