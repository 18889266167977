import toast from 'react-hot-toast'

export default function handleOnline(isFirstCall: boolean = false, isOnline: boolean, changeOnline: Function) {
  if (navigator.onLine) {
    if (isOnline && !isFirstCall) {
      toast.success('The device is connected to the internet!', { position: 'top-center' })
    }
    changeOnline(true)
  } else {
    changeOnline(false)
    toast.error('Your internet is out!', { position: 'top-center' })
  }
}