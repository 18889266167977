import { lazy, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

// Types
import { IRoute, IRoutes } from 'types'
interface IGetChildElement {
  path: string
  element: ReactNode
}

// Layouts
const MainLayout = lazy(() => import('layouts/MainLayout'))

// Auth Pages
const Login = lazy(() => import('pages/auth/Login'))
const ForgotPassword = lazy(() => import('pages/auth/ForgotPassword'))

// Dashboard Page
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'))

// Products Pages
const Products = lazy(() => import('pages/products/Products'))
const ProductsAdd = lazy(() => import('pages/products/add/ProductsAdd'))

// Categories Pages
const Categories = lazy(() => import('pages/categories/Categories'))
const CategoryAdd = lazy(() => import('pages/categories/add/CategoryAdd'))

// Packaging Pages
const Packaging = lazy(() => import('pages/packaging/Packaging'))
const PackagingAdd = lazy(() => import('pages/packaging/add/PackagingAdd'))

// Brands Pages
const Brands = lazy(() => import('pages/brands/Brands'))
const BrandAdd = lazy(() => import('pages/brands/add/BrandAdd'))

// Manufacturers Pages
const Manufacturers = lazy(() => import('pages/manufacturers/Manufacturers'))
const ManufacturerAdd = lazy(() => import('pages/manufacturers/add/ManufacturerAdd'))

// Colors Pages
const Colors = lazy(() => import('pages/colors/Colors'))
const ColorAdd = lazy(() => import('pages/colors/add/ColorAdd'))

// Company News Pages
const SalesNews = lazy(() => import('pages/salesNews/SalesNews'))
const SalesNewsAdd = lazy(() => import('pages/salesNews/add/SalesNewsAdd'))

// Company News Pages
const CompanyNews = lazy(() => import('pages/companyNews/CompanyNews'))
const CompanyNewsAdd = lazy(() => import('pages/companyNews/add/CompanyNewsAdd'))

// Users Pages
const Users = lazy(() => import('pages/users/Users'))

// Admins Pages
const Admins = lazy(() => import('pages/admins/Admins'))
const AdminAdd = lazy(() => import('pages/admins/add/AdminAdd'))

// Admins Pages
const AdminRoles = lazy(() => import('pages/adminRoles/AdminRoles'))
const AdminRolesAdd = lazy(() => import('pages/adminRoles/add/AdminRolesAdd'))

// Admin Profile pages
const Profile = lazy(() => import('pages/user/Profile'))
const ProfileSettings = lazy(() => import('pages/user/ProfileSettings'))

// Company About
const Settings = lazy(() => import('pages/settings/Settings'))

const getChildElement = (path: string, element: ReactNode): IGetChildElement => ({ path, element })
const getRedirect = (path: string = '*'): IGetChildElement => ({ path: path, element: <Navigate to='list' /> })

// Admins routes paths
const ROUTE_PATH_NAMES = {
  ALL: '*',
  HOME: '/',
  NEWS: '/news/*',
  USER: '/user/*',
  USERS: '/users/*',
  ROLES: '/roles/*',
  BRANDS: '/brands/*',
  TYPES: '/types/*',
  ADMINS: '/admins/*',
  SETTINGS: '/settings',
  PRODUCTS: '/products/*',
  DASHBOARD: '/dashboard/*',
  PACKAGING: '/packaging/*',
  SALES_NEWS: '/sales-news/*',
  CATEGORIES: '/categories/*',
  MANUFACTURERS: '/manufacturers/*',
}

// Guest router paths
export const guestRoutes: IRoute[] = [
  { path: '/login', element: <Login /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '*', element: <Navigate to='/login' /> },
]

// Default admin routes
export const defaultAdminRoutes: IRoutes[] = [
  { path: ROUTE_PATH_NAMES.DASHBOARD, element: <Dashboard /> },
  { path: ROUTE_PATH_NAMES.ALL, element: <Navigate to='/dashboard' /> },
  { path: ROUTE_PATH_NAMES.HOME, element: <Navigate to='/dashboard' /> },
  { path: ROUTE_PATH_NAMES.USER, children: [getChildElement('profile', <Profile />), getChildElement('settings', <ProfileSettings />), getRedirect('profile')] },
]

// Admins router
const adminRoutes: IRoutes[] = [
  { path: ROUTE_PATH_NAMES.SETTINGS, element: <Settings /> },
  { path: ROUTE_PATH_NAMES.USERS, children: [getChildElement('list', <Users />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.BRANDS, children: [getChildElement('list', <Brands />), getChildElement('add', <BrandAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.TYPES, children: [getChildElement('list', <Colors />), getChildElement('add', <ColorAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.ADMINS, children: [getChildElement('list', <Admins />), getChildElement('add', <AdminAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.PRODUCTS, children: [getChildElement('list', <Products />), getChildElement('add', <ProductsAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.ROLES, children: [getChildElement('list', <AdminRoles />), getChildElement('add', <AdminRolesAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.NEWS, children: [getChildElement('list', <CompanyNews />), getChildElement('add', <CompanyNewsAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.PACKAGING, children: [getChildElement('list', <Packaging />), getChildElement('add', <PackagingAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.CATEGORIES, children: [getChildElement('list', <Categories />), getChildElement('add', <CategoryAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.SALES_NEWS, children: [getChildElement('list', <SalesNews />), getChildElement('add', <SalesNewsAdd />), getRedirect()] },
  { path: ROUTE_PATH_NAMES.MANUFACTURERS, children: [getChildElement('list', <Manufacturers />), getChildElement('add', <ManufacturerAdd />), getRedirect()] },
]

// Generate routes based on authentication status
function generateRoutes(authed: boolean, roles: string[]): IRoutes[] {
  if (!authed) {
    return guestRoutes
  } else {
    const validRoutes: IRoutes[] = []

    roles?.map((role: string) => {
      if (role === 'boss-role') {
        validRoutes?.push(...adminRoutes)
      } else {
        adminRoutes?.map((adminRoute: IRoutes) => {
          if (role === adminRoute?.path?.split('/')?.join('')?.split('*')?.join('')) {
            validRoutes?.push(adminRoute)
          }
        })
      }
    })

    return [
      {
        path: ROUTE_PATH_NAMES.HOME,
        element: <MainLayout />,
        children: [...defaultAdminRoutes, ...validRoutes],
      },
    ]
  }
}

export default generateRoutes