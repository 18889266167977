export default function switchTheme(param: 'switch' | 'get') {
  if (param === 'get') {
    if (localStorage.getItem('$abrormarketadmindashboard$theme$')) {
      if (localStorage.getItem('$abrormarketadmindashboard$theme$') === 'dark') {
        document.documentElement.classList.add('dark')
        document.documentElement.classList.remove('light')
        document.documentElement.style.colorScheme = 'dark'
      } else {
        document.documentElement.classList.add('light')
        document.documentElement.classList.remove('dark')
        document.documentElement.style.colorScheme = 'light'
      }
    }
  } else if (param === 'switch') {
    if ((localStorage.getItem('$abrormarketadmindashboard$theme$') && localStorage.getItem('$abrormarketadmindashboard$theme$') === 'dark') || document.documentElement.className.includes('dark')) {
      localStorage.setItem('$abrormarketadmindashboard$theme$', 'light')
      document.documentElement.classList.add('light')
      document.documentElement.classList.remove('dark')
      document.documentElement.style.colorScheme = 'light'
    } else {
      localStorage.setItem('$abrormarketadmindashboard$theme$', 'dark')
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      document.documentElement.style.colorScheme = 'dark'
    }
  }
}